@keyframes marquee {
  from {
    transform: translateX(100%);
  }
  to {
    transform: translateX(-310%);
  }
}

.animate-marquee {
  animation: marquee 30s linear infinite;
}
