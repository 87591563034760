.more {
  margin-top: 50px;
  margin-bottom: 150px;
  font-family: "Poppins", sans-serif;
}

.more a {
  margin-bottom: 25px;
  color: #d63031;
  text-align: center;
}

.more a:hover {
  color: #a31f1f;
  transition: 0.3s;
}

.more .carousel img {
  margin-left: auto;
  margin-right: auto;
}

.more h5 {
  font-size: 10pt;
  font-weight: 200;
  letter-spacing: 1px;
  line-height: 25px;
}

.more h3 {
  text-transform: uppercase;
  letter-spacing: 1px;
}

.more .row h4 {
  font-size: 12pt;
  font-weight: 200;
}

.more .row h6 {
  font-size: 9pt;
  letter-spacing: 1px;
  line-height: 20px;
}

.more .row img {
  max-width: 70%;
}

@media (min-width: 300px) and (max-width: 568px) {
  .more a {
    font-size: 9pt;
  }

  .more h5 {
    font-size: 8pt;
    line-height: 18px;
  }

  .more h3 {
    font-size: 13pt;
  }

  .more .row h4 {
    font-size: 10pt;
  }

  .more #arcid,
  .more #floid {
    max-width: 100%;
  }

  .more .row h6 {
    font-size: 7pt;
    letter-spacing: 1px;
    line-height: 15px;
  }

  .more .row img {
    max-width: 100%;
  }
}
