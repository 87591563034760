.about {
  font-family: "Poppins", sans-serif;

  .banner {
    background-image: linear-gradient(grey, grey),
      url(https://res.cloudinary.com/dp5hwzmhm/image/upload/v1706966940/maarten-van-den-heuvel--WWFGrfm7qk-unsplash-min_vt8om3.jpg);
    background-repeat: no-repeat;
    background-size: 250%;
    background-position: 50% 50%;
    background-blend-mode: multiply;
  }
}

.about a:hover {
  transition: 0.3s;
}
