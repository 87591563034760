.touch {
  margin-bottom: 50px;
  font-family: "Poppins", sans-serif;
}

.touch h2 span {
  display: block;
  font-family: "Prompt", sans-serif;
  font-size: 64pt;
  text-shadow: 2px 2px #ff0000;
}

.touch hr {
  width: 80%;
}

.touch h4 {
  color: #2d3436;
}

.touch h6 {
  font-size: 8pt;
  font-weight: 200;
}

.touch a {
  padding: 10px;
  padding-left: 35px;
  padding-right: 35px;
  border-radius: 15px;
  font-size: 10pt;
}

.touch a:hover {
  text-decoration: none;
  transition: 0.3s;
  color: #ffffff;
}

.touch .message-work {
  padding: 25px;
  padding-left: 50px;
  padding-right: 50px;
  -webkit-box-shadow: 0px 0px 30px 5px rgba(220, 221, 225, 1);
  -moz-box-shadow: 0px 0px 30px 5px rgba(220, 221, 225, 1);
  box-shadow: 0px 0px 30px 5px rgba(220, 221, 225, 1);
  border-radius: 20px;
}

.touch .message-work label {
  color: rgb(100, 100, 100);
  font-size: 9pt;
  font-weight: 600;
}

.touch .message-work input[type="text"],
.touch .message-work input[type="email"],
.touch .message-work input[type="number"],
.touch .message-work select,
.touch .message-work textarea {
  font-size: 9pt;
  color: #2d3436;
  border: 0.75% solid #2d3436;
  border-radius: 8px;
}

.touch .message-work .form-text {
  font-size: 8pt;
}

.touch .message-work button {
  font-size: 9pt;
}

/* Chrome, Safari, Edge, Opera */
input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

/* Firefox */
input[type="number"] {
  -moz-appearance: textfield;
}
