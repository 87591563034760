@tailwind base;
@tailwind components;
@tailwind utilities;

@import url("https://fonts.googleapis.com/css2?family=Poppins:wght@200;400;600;900&display=swap");
@import url("https://fonts.googleapis.com/css2?family=Ubuntu:wght@700&display=swap");
@import url("https://fonts.googleapis.com/css2?family=Raleway:ital,wght@0,700;1,800&display=swap");
@import url("https://fonts.googleapis.com/css2?family=Quicksand:wght@300;700&display=swap");
@import url("https://fonts.googleapis.com/css2?family=Nunito:wght@400;900&display=swap");
@import url("https://fonts.googleapis.com/css2?family=Fugaz+One&display=swap");
@import url("https://fonts.googleapis.com/css2?family=Passion+One:wght@400;700&display=swap");
@import url("https://fonts.googleapis.com/css2?family=Kanit:wght@100;600&display=swap");
@import url("https://fonts.googleapis.com/css2?family=Prompt:wght@200;700;800&display=swap");
@import url("https://fonts.googleapis.com/css2?family=Yellowtail&display=swap");

@import "./global-scss/color.scss";
@import "./global-scss/fonts.scss";
// @import './global-scss/globals.scss';
// @import './global-scss/buttons.scss';
// @import './global-scss/links.scss';
// @import './global-scss/list.scss';
// @import './global-scss/tooltip.scss';
// @import './global-scss/scrollbar.scss';

@import "./components/BaseLayout/Footer/index.scss";
@import "./components/BaseLayout/NavBar/index.scss";
@import "./components/RunningText/index.scss";
@import "./components/SocialMedia/index.scss";

@import "./pages/Home/index.scss";
@import "./pages/About/index.scss";
@import "./pages/Services/index.scss";
@import "./pages/Work/index.scss";
@import "./pages/Work/WorkPlan/index.scss";
@import "./pages/Work/More/index.scss";
@import "./pages/Contact/index.scss";
@import "./pages/Contact/GetInTouch/index.scss";
