.home {
  font-family: "Prompt", sans-serif;

  .banner {
    background-repeat: no-repeat;
    background-size: 100%;
    background-position: 50% 50%;
    background-blend-mode: multiply;
  }

  h2 {
    span {
      display: block;
      color: $secondary;
      font-size: $xlarge;
      font-weight: 800;
      text-transform: uppercase;
      letter-spacing: 4px;
    }
  }

  h5 {
    font-size: $regular;
    font-family: "Poppins", sans-serif;
  }

  h6 {
    font-size: $small;
    font-weight: 200;
  }

  a:hover {
    transition: 0.3s;
    text-decoration: none;
  }
}
