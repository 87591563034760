.socmed {
  a {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 50px;
    height: 50px;
    padding: 0px;
    margin: 0px;
    line-height: 0px;
    background-color: #ffffff;
    border: 1px solid #cbcbcb;
    box-shadow: 2px 2px 12px rgba(0, 0, 0, 0.2);
  }

  a:hover {
    background-color: #2d3436;
    box-shadow: 2px 2px 12px rgba(0, 0, 0, 0.2);
    transition: all ease 0.5s;
  }

  #a-socmed {
    border-radius: 10px 0px 0px 0px;
    -moz-border-radius: 10px 0px 0px 0px;
    -webkit-border-radius: 10px 0px 0px 0px;
  }

  #d-socmed {
    border-radius: 0px 0px 0px 10px;
    -moz-border-radius: 0px 0px 0px 10px;
    -webkit-border-radius: 0px 0px 0px 10px;
  }
}

@media (min-width: 300px) and (max-width: 568px) {
  .socmed {
    visibility: hidden;
  }
}
