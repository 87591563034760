.services {
  font-family: "Poppins", sans-serif;

  .banner {
    /* Set background image */
    background-image: linear-gradient(
        to bottom,
        rgba(255, 255, 255, 0),
        rgba(0, 0, 0, 0.6)
      ),
      url(https://res.cloudinary.com/dp5hwzmhm/image/upload/v1706967119/hugh-han-lR_Mu_30Dic-unsplash-min_ghpbhk.jpg);
    /* Set background properties */
    background-repeat: no-repeat;
    background-size: cover; /* Adjust as needed */
    background-position: center center; /* Adjust as needed */
  }

  .secondary-banner {
    /* Set background image */
    background-image: linear-gradient(
        to bottom,
        rgba(255, 255, 255, 0),
        rgba(0, 0, 0, 0.6)
      ),
      url(https://res.cloudinary.com/dp5hwzmhm/image/upload/v1710234049/mediensturmer-aWf7mjwwJJo-unsplash_pk0eow.jpg);
    /* Set background properties */
    background-repeat: no-repeat;
    background-size: cover; /* Adjust as needed */
    background-position: center center; /* Adjust as needed */
  }
}
