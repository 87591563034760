// $primary: #c060a1;
// $secondary: #00dcb8;
// $tertiary: #f4dddd;
// $strong: #140222;
// $indigo: #008ddc;
// $violet: #a823f9;
$soft: #dfe6e9;
$secondary: #c8d6e5;

$tertiary: #646464;
$hover: #343a40;

$success: #60c098;
$warning: #c0ab60;
$error: #c06060;

$border: rgba(255, 255, 255, 0.15);
$white: #ffffff;
$transparent: transparent;
